import React, {Component} from 'react';
import {graphql, StaticQuery, Link} from "gatsby";
import styled from "styled-components";
import {Container} from "reactstrap";
import Layout from "components/Layout/Layout"
import AwardList from 'components/AwardList/AwardList'
import HeaderCopy from "components/shared/HeaderCopy"
import {media} from "utils/Media"
import iconFilter from "images/icons/icon-filter.svg"
import IconClose from "images/icons/icon-close.svg"
import iconTick from "images/icons/icon-tick.svg"
import IconDownload from 'images/icons/icon-download.svg'

const Flex = styled.div`
    display: flex;
    flex-flow: row wrap;
    align-content: space-between;
    justify-content: space-between;
`

const CategoryPanel = styled.div`
    width: 220px;
    
    .title {
        font-size: 1.125rem;
        margin-bottom: 0.5rem;   
    }
    
    .sticky {
        border-right: 1px solid ${props => props.theme.colors.lightGrey};
        border-bottom: 1px solid ${props => props.theme.colors.lightGrey};
        padding-bottom: 1rem !important;
    }
    
    .sticky.active {
        position: fixed;
        width: 100%;
        z-index: 501;
        bottom: 0;
        height: 58px;
        overflow: hidden;
        background: white;
        left: 0;
        padding: 1rem;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
        
        &.open {
            height: 100%;
        }
        
        @media ${media.md} {
            box-shadow: none;   
            padding: 0 0 0 1rem;
            left: initial;
            width: 220px;
            height: initial;
            overflow: auto;
            bottom: initial;    
            z-index: 1;
            top: 65px;
            max-height: calc(100% - 65px);
        }
    }
`

const Filter = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    cursor: pointer;
    
    @media ${media.md} {
        cursor: normal;
    }
    
    &.open {
        flex-flow: row wrap;
        align-content: space-between;
        justify-content: space-between;
    }
    
    p {
        padding-left: 1rem;
        margin-bottom: 0 !important;
        
        @media ${media.md} {
            padding-left: 0;
        }
    }
`

const Content = styled.div`
    height: 100%;
    width: 100%;
    
    @media ${media.md} {
        width: calc(100% - 240px);
    }
`

const CategoryList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
     
    li {
        font-size: 1rem;
        margin-bottom: 0.5rem;
        cursor: pointer;
        
        a {
            text-decoration: underline;
            
            &:hover {
                color: ${props => props.theme.colors.red};
            }
            
            img {
                padding-left: 1rem;
            }
        }
    }
`

const Query = () => (
    <StaticQuery
        query={graphql`
            query {
                allAwardsJson {
                    edges {
                      node {
                        category
                        items {
                            title
                            content
                            selector
                            link
                        }
                      }
                    }
                }
            }
		`}
        render={data => (
            <ResourcePage data={data}/>
        )}
    />
)

class ResourcePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sticky: false,
            filterOpen: false
        }
    }

    componentDidMount() {
        const width = window.innerWidth;

        if (width < 768) {
            this.setState({
                sticky: true
            })
        }

        window.addEventListener('scroll', this.handleScroll, true)
        window.addEventListener('resize', this.handleScroll, true)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, true)
        window.removeEventListener('resize', this.handleScroll, true)
    }

    handleScroll = () => {
        let scrollPosition = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop)

        const width = window.innerWidth;

        if (width > 767) {
            let sticky = scrollPosition < 156 ? false : true

            this.setState({
                sticky
            })
        } else {
            this.setState({
                sticky: true
            })
        }
    }

    toggleFilter = () => {
        const filterOpen = !this.state.filterOpen;

        this.setState({
            filterOpen
        })
    }

    render() {
        return (
            <Layout slug="circle-of-life-award" title="Circle of Life Award">
                <HeaderCopy title="Circle of Life Award"/>
                <Container fluid={true} className="my-4">
                    <Container>
                        <Flex>
                            <CategoryPanel>
                                <div className={`sticky ${this.state.sticky && 'active'} ${this.state.filterOpen && 'open'}`}>
                                    <Filter className={this.state.filterOpen && 'open'}>
                                        {this.state.filterOpen ?
                                            <>
                                                <img onClick={this.toggleFilter} src={IconClose} alt="" className="d-md-none"/>
                                            </>
                                            :
                                            <>
                                                <img onClick={this.toggleFilter} src={iconFilter} alt="" className="d-md-none"/>
                                                <p className="title">Quick links</p>
                                            </>
                                        }
                                    </Filter>
                                    <CategoryList>
                                        <li className="mt-4"><strong>How to use this resource</strong></li>
                                        <li><a href="/pdfs/Citizenship_How_To_Guide.pdf" target="_blank" download>Download PDF<img src={IconDownload} alt="" /></a></li>
                                        <li className="mt-4"><strong>Apply for the Circle of Life Award</strong></li>
                                        <li><Link to="/circle-of-life-award/application">Apply now<img src={iconTick} alt="" /></Link></li>
                                    </CategoryList>
                                </div>
                            </CategoryPanel>
                            <Content>
                                <AwardList awards={this.props.data.allAwardsJson.edges}/>
                            </Content>
                        </Flex>
                    </Container>
                </Container>
            </Layout>
        );
    }
}

export default Query;