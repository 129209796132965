import React, {Component} from 'react';
import styled from "styled-components";
import {media} from "utils/Media"
import KalturaModal from "components/shared/KalturaModal"
import IconDownload from 'images/icons/icon-download.svg'
import IconDownloadWhite from 'images/icons/icon-download-white.svg'
import IconPlay from 'images/icons/icon-play.svg'
import IconPlayWhite from 'images/icons/icon-play-white.svg'
import IconArrow from 'images/icons/icon-arrow.svg'
import IconArrowWhite from 'images/icons/icon-arrow-white.svg'
import cardTopBlue from "images/Resources/card-top-blue.jpg"
import cardTopGreen from "images/Resources/card-top-green.jpg"
import cardTopOrange from "images/Resources/card-top-orange.jpg"
import cardTopPurple from "images/Resources/card-top-purple.jpg"
import cardTopYellow from "images/Resources/card-top-yellow.jpg"
import cardTopPink from "images/Resources/card-top-pink.jpg"

const Resources = styled.div`
    margin-bottom: 1rem;
    position: relative;
    
    .card-deck {
        display: flex;
        flex-flow: row wrap;
        justify-content: left;
    }
    
    .led {
        font-weight: bold;
        margin-left: 0.5rem;
        font-size: 1.125rem;
        
        @media ${media.sm} {
            margin-left: 0;
            position: absolute;
            top: 5px;
            right: 1rem;
        }
    }
    
    h2 {
        padding-left: 0.5rem;
    }
`

const Card = styled.div`
   width: 200px;
    height: 275px;
    position: relative;
    margin: 1rem 0.5rem 1rem;
    padding: calc(25px + 1rem) 1rem 1rem;
    box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.15);
    transition: transform 200ms ease-in-out;
    display: inline-block;
    
    &:hover {
        transform: scale(1.05);
    }
    
    .card-header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 25px;
        background: url(${cardTopPink}) no-repeat center center;
        background-size: 100%;
    }

  .card-body {
    padding: 0 !important;
  }

  .card-footer {
    background: none !important;
    border: none !important;
  }
    
    &.pink {
        .card-header {
            background: url(${cardTopPink}) no-repeat center center;
            background-size: 100%;
        }
    }
     
    &.orange {
        .card-header {
            background: url(${cardTopOrange}) no-repeat center center;
            background-size: 100%;
        }
    }
    
    &.blue {
        .card-header {
            background: url(${cardTopBlue}) no-repeat center center;
            background-size: 100%;
        }
    }
    
    &.green {
        .card-header {
            background: url(${cardTopGreen}) no-repeat center center;
            background-size: 100%;
        }
    }
    
    &.purple {
        .card-header {
            background: url(${cardTopPurple}) no-repeat center center;
            background-size: 100%;
        }
    }
    
    &.yellow {
        .card-header {
            background: url(${cardTopYellow}) no-repeat center center;
            background-size: 100%;
        }
    }
    
    h4 {
        font-size: 1.125rem;
    }
    
    p {
        font-size: 0.875rem;
    }
    
    a {
        text-decoration: underline;
        
        &:hover {
            color: ${props => props.theme.colors.red};
        }
    }
`

const DownloadButton = styled.a`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 200px;
    height: 50px;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 200%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: none;
    color: ${props => props.theme.colors.white} !important;
    transition: all 150ms ease-in-out;
    cursor: pointer;
    text-decoration: none !important;
    
    &.pink {
        background-color: ${props => props.theme.colors.pink};
    }
    
    &.orange {
        background-color: ${props => props.theme.colors.orange};
    }
    
    &.blue {
        background-color: ${props => props.theme.colors.blue};
    }
    
    &.green {
        background-color: ${props => props.theme.colors.green};
    }
    
    &.purple {
        background-color: ${props => props.theme.colors.purple};
    }
    
    &.yellow {
        background-color: ${props => props.theme.colors.yellow};
        color: ${props => props.theme.colors.black} !important;
    }
    
    img {
        padding-left: 1rem;
    }
    
    .hover {
        display: none;
    }
    
    &:hover {
        color: ${props => props.theme.colors.white} !important;
        background-color: ${props => props.theme.colors.black};
        
        .non-hover {
            display: none;
        }
        
        .hover {
            display: inline-block;
        }
    }
    
    @media ${media.lg} {
        width: 200px;
    }
    
    img {
        margin-left: 10px;
    }
`

const WatchButton = styled.button`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 200px;
    height: 50px;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 200%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: none;
    color: ${props => props.theme.colors.white} !important;
    transition: all 150ms ease-in-out;
    cursor: pointer;
    text-decoration: none !important;
    
    &.pink {
        background-color: ${props => props.theme.colors.pink};
    }
    
    &.orange {
        background-color: ${props => props.theme.colors.orange};
    }
    
    &.blue {
        background-color: ${props => props.theme.colors.blue};
    }
    
    &.green {
        background-color: ${props => props.theme.colors.green};
    }
    
    &.purple {
        background-color: ${props => props.theme.colors.purple};
    }
    
    &.yellow {
        background-color: ${props => props.theme.colors.yellow};
        color: ${props => props.theme.colors.black} !important;
    }
    
    img {
        padding-left: 1rem;
    }
    
    .hover {
        display: none;
    }
    
    &:hover {
        color: ${props => props.theme.colors.white} !important;
        background-color: ${props => props.theme.colors.black};
        
        .non-hover {
            display: none;
        }
        
        .hover {
            display: inline-block;
        }
    }
    
    @media ${media.lg} {
        width: 200px;
    }
    
    img {
        margin-left: 10px;
    }
`

class AwardList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false,
        }
    }

    toggleModal = (e, src) => {
        e.preventDefault();

        this.setState({
            src: src,
            modalOpen: !this.state.modalOpen
        });
    }

    listAwards = (category, color) => {
        const getAwards =  this.props.awards.map(({node}) => {
            return node.items.map((item, id) => {
                if (category === node.category) {
                    return (
                        <Card key={`${category}-${id}`} className={`${color}`}>
                            <div className="card-header">
                            </div>
                            <div className="card-body d-flex flex-column">
                                <h4>{item.title}</h4>
                                <div dangerouslySetInnerHTML={{__html: item.content}}/>
                            </div>
                            <div className="card-footer">
                                {item.selector === 'pdf' &&
                                <DownloadButton download target="_blank" href={item.link} className={color}>Download PDF
                                    {color === 'yellow' ?
                                        <>
                                            <img className="non-hover" src={IconDownload} alt="" />
                                            <img className="hover" src={IconDownloadWhite} alt="" />
                                        </>
                                        :
                                        <img src={IconDownloadWhite} alt="" />
                                    }
                                </DownloadButton>
                                }
                                {item.selector === 'video' &&
                                <WatchButton onClick={(e) => this.toggleModal(e, item.link)} role="button" tabindex="0" aria-label="Open video" className={color}>Watch videos
                                    {color === 'yellow' ?
                                        <>
                                            <img className="non-hover" src={IconPlay} alt="" />
                                            <img className="hover" src={IconPlayWhite} alt="" />
                                        </>
                                        :
                                        <img src={IconPlayWhite} alt="" />
                                    }
                                </WatchButton>
                                }
                                {item.selector === 'url' &&
                                <DownloadButton target={!item.link.includes("lionkingeducation.co.uk") ? '_blank': '_self'} download={!item.link.includes("lionkingeducation.co.uk")} href={item.link} className={color}>Find out more
                                    {color === 'yellow' ?
                                        <>
                                            <img className="non-hover" src={IconArrow} alt="" />
                                            <img className="hover" src={IconArrowWhite} alt="" />
                                        </>
                                        :
                                        <img src={IconArrowWhite} alt="" />
                                    }
                                </DownloadButton>
                                }
                            </div>
                        </Card>
                    )
                }
            })
        })

        return (
            <Resources>
                {category === 'session-1a' &&
                    <div>
                        <h2><strong>Session 1A:</strong> Aspirations</h2>
                        <p className="led">Teacher-Led</p>
                        <p className="ml-2">In sessions 1A and 1B, students will develop aspirations for their community. They will explore potential project ideas.</p>
                    </div>
                }
                {category === 'session-1b' &&
                <div>
                    <h2><strong>Session 1B:</strong> Aspirations</h2>
                    <p className="led">Student-Led</p>
                </div>
                }
                {category === 'session-2a' &&
                <div>
                    <h2><strong>Session 2A:</strong> Planning</h2>
                    <p className="led">Teacher-Led</p>
                    <p className="ml-2">Students will start planning how to make their projects happen.</p>
                </div>
                }
                {category === 'session-2b' &&
                <div>
                    <h2><strong>Session 2B:</strong> Planning</h2>
                    <p className="led">Student-Led</p>
                </div>
                }
                {category === 'session-3a' &&
                <div>
                    <h2><strong>Session 3A:</strong> Implementation</h2>
                    <p className="led">Teacher-Led</p>
                    <p className="ml-2">Students will define their roles, key actions and timelines for their projects.</p>
                </div>
                }
                {category === 'session-3b' &&
                <div>
                    <h2><strong>Session 3B:</strong> Implementation</h2>
                    <p className="led">Student-Led</p>
                </div>
                }
                <div className="card-deck">
                    {getAwards}
                </div>
            </Resources>
        )
    }

    render() {
        return (
            <div>
                <h2 className="pl-2 mb-5 narrow">Empower your students to create their own social action project to benefit their community.</h2>
                {this.listAwards('session-1a', 'purple')}
                {this.listAwards('session-1b', 'pink')}
                {this.listAwards('session-2a', 'purple')}
                {this.listAwards('session-2b', 'pink')}
                {this.listAwards('session-3a', 'purple')}
                {this.listAwards('session-3b', 'pink')}
                <KalturaModal toggleModal={this.toggleModal} src={this.state.src} modalOpen={this.state.modalOpen}/>
            </div>
        );
    }
}

export default AwardList;